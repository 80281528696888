












































import { Vue, Component, PropSync } from "vue-property-decorator";
import { mapGetters } from "vuex";

import * as types from "@/store/types";

@Component({
    computed: {
        ...mapGetters({
            filterLoading: types.APP_FILTER_LOADING
        })
    }
})
export default class FilterFormPanelBase extends Vue {
    @PropSync("filterFormPanelShow", { type: Boolean || null })
    syncFilterFormPanelShow!: null;

    /**
     * Methods
     */
    onFilterPanelInput(state) {
        this.syncFilterFormPanelShow = state;
    }

    setFilter() {
        this.$emit("on-filter-updated");
    }
}
